body {
	background-color: #F8F9FA;
	color: #495057;
}

.bg-danger-mild {
	background-color: #ffd7d5;
}

/*Textfields*/
.form-input-box .form-input, .form-input-box textarea, .form-input-box .rc-time-picker-input{
    font-size: 12px;
    line-height: 15px;
    font-weight: 600;
    display: block;
    width: 100%;
    height: 43px;
    padding: 5px 15px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ECE9E9;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.05) 5px 5px 5px;
    color: #58666e;
}

.form-input-box textarea {
    padding: 15px 15px;
    height: auto;
}

.form-input-box .error .form-input, .form-input-box .error .rc-time-picker-input {
  border-color: red;
}

.form-input-box .error .form-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: red;
}


.form-input-box .form-input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    border-color: #d3d3d3;
}

.form-input-box .form-input::placeholder {
    color: #ACABAC;
}

.form-input-box.icon {
    position: relative;
}

.form-input-box.icon .form-input {
    padding-left: 40px;
}

.form-input-box.icon i {
    position: absolute;
    top: 16px;
    left: 20px;
    font-size: 11px;
    color: #ACABAC;
    z-index: 9;
}

.form-input-box.lg .form-input{
    height: 48px;
    font-size: 14px;
    line-height: 18px;
}

.form-input-box.lg i {
    top: 18px;
    font-size: 13px;
}

.form-input-box.sm .form-input{
    height: 34px;
    font-size: 11px;
    line-height: 13px;
}

.form-input-box.sm.icon i {
    top: 13px;
    font-size: 10px;
}

.form-input-box .form-input.disabled,
.form-input-box .form-input[disabled],
fieldset[disabled] .btn-repup,
.form-input-box .form-input.disabled:hover,
.form-input-box .form-input[disabled]:hover,
fieldset[disabled] .btn-repup:hover,
.form-input-box .form-input.disabled:focus,
.form-input-box .form-input[disabled]:focus,
fieldset[disabled] .form-input-box .form-input.disabled:focus{
  background-color: #eee !important;
  pointer-events: none;
}

.form-input-group {
    display: table;
    border-collapse: collapse;
    width: 100%;
}

.form-input-group>div {
    display: table-cell;
    vertical-align: middle;
}

.form-input-group button {
    width: 100%;
}
.form-input-group button.btn-repup-icon {
    max-width: 60px;
}

.form-input-group>div:first-child>input, .form-input-group>div:first-child>button{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: 0px;
}
.form-input-group>div:last-child>input, .form-input-group>div:last-child>button{
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.form-input-group>div:not(:first-child):not(:last-child)>input, .form-input-group>div:not(:first-child):not(:last-child)>button {
    border-left: 0px;
    border-right: 0px;
    border-radius: 0px;
}

/*Textfields*/


/*Buttons*/
.btn-repup {
    font-weight: 600;
    border-radius: 3px;
    box-shadow:  3px 3px 3px rgba(0, 0, 0, 0.05);
    outline: 0!important;
    display: inline-block;
    padding: 12.5px 30px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid #E9E5E5;
    color: #7B7A7B;
    background-color: #fff;
}

.btn-repup:hover,
.btn-repup:active,
.btn-repup:focus{
    background-color: #f9f9f9;
    border-color: #D1D1D1;
}

.btn-repup.disabled,
.btn-repup[disabled],
fieldset[disabled] .btn-repup,
.btn-repup.disabled:hover,
.btn-repup[disabled]:hover,
fieldset[disabled] .btn-repup:hover,
.btn-repup.disabled:focus,
.btn-repup[disabled]:focus,
fieldset[disabled] .btn-repup:focus,
.btn-repup.disabled:active,
.btn-repup[disabled]:active,
fieldset[disabled] .btn-repup:active,
.btn-repup.disabled.active,
.btn-repup[disabled].active,
fieldset[disabled] .btn-repup.active {
  background-color: #ccc !important;
  border-color: #bbb !important;
  color: #eee;
  pointer-events: none;
  cursor: ;
  background-image: none;
}

.btn-repup-xs {
    padding: 7.5px 15px;
    font-size: 10px;
    line-height: 12px;
}

.btn-repup-sm {
    padding: 9.5px 25px;
    font-size: 11px;
    line-height: 13px;
}

.btn-repup-lg {
    padding: 14.5px 40px;
    font-size: 14px;
    line-height: 17px;
}

.btn-repup i {
    margin-right: 10px;
    font-size: 11px;
}

.btn-repup.icon-right i {
    margin-left: 10px;
}

.btn-repup.icon-pull-right i {
    float: right;
    margin-left: 15px;
    margin-right: 0px;
    line-height: inherit;
}

.btn-repup-outline {
    box-shadow: none;
    background-color: #fff; 
}

.txt-repup-gradient {
  background-image: -webkit-linear-gradient(left, #3E81CE, #04CC80);
  background-image: -moz-linear-gradient(left, #3E81CE, #04CC80);
  background-image: -ms-linear-gradient(left, #3E81CE, #04CC80);
  background-image: -o-linear-gradient(left, #3E81CE, #04CC80);
  background-image: linear-gradient(to right, #3E81CE, #04CC80);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.border-gradient {
  border-image: linear-gradient(to right, #3E81CE 0%, #04CC80 100%) 1 round;
}

.b-a-2x {
  border: 2px solid #dee5e7;
}

.txt-repup-gradient:hover {
  background-image: -webkit-linear-gradient(left, #04CC80, #3E81CE);
  background-image: -moz-linear-gradient(left, #04CC80, #3E81CE);
  background-image: -ms-linear-gradient(left, #04CC80, #3E81CE);
  background-image: -o-linear-gradient(left, #04CC80, #3E81CE);
  background-image: linear-gradient(to right, #04CC80, #3E81CE);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.btn-repup-gradient {
    border: 0px solid transparent;
    color: #FFFFFF;
    background-color: #3E81CE;
    background-image: -webkit-gradient(linear, left top, right top, from(#3E81CE), to(#04CC80));
    background-image: -webkit-linear-gradient(left, #3E81CE, #04CC80);
    background-image: -moz-linear-gradient(left, #3E81CE, #04CC80);
    background-image: -ms-linear-gradient(left, #3E81CE, #04CC80);
    background-image: -o-linear-gradient(left, #3E81CE, #04CC80);
    background-image: linear-gradient(to right, #3E81CE, #04CC80);
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#3E81CE, endColorstr=#04CC80);
}

.btn-repup-gradient:hover {
    background-color: #04CC80; background-image: -webkit-gradient(linear, left top, right top, from(#04CC80), to(#3E81CE));
    background-image: -webkit-linear-gradient(left, #04CC80, #3E81CE);
    background-image: -moz-linear-gradient(left, #04CC80, #3E81CE);
    background-image: -ms-linear-gradient(left, #04CC80, #3E81CE);
    background-image: -o-linear-gradient(left, #04CC80, #3E81CE);
    background-image: linear-gradient(to right, #04CC80, #3E81CE);
    filter:progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#04CC80, endColorstr=#3E81CE);
}

.btn-repup-gradient.btn-repup-outline>i {
    -webkit-text-fill-color: #3E81CE;
}

.btn-repup-gradient.btn-repup-outline {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-left: 1px solid #3E81CE;
    border-top: 1px solid #3E81CE;
    border-right: 1px solid #04CC80;
    border-bottom: 1px solid #04CC80;
}

.btn-repup-gradient.btn-repup-outline:hover,
.btn-repup-gradient.btn-repup-outline:active,
.btn-repup-gradient.btn-repup-outline:focus {
    -webkit-text-fill-color: #fff;
    background-color: #3E81CE;
    background: #3E81CE;
    border-color: #3E81CE;
}

.btn-repup-gradient.btn-repup-outline:hover i,
.btn-repup-gradient.btn-repup-outline:active i,
.btn-repup-gradient.btn-repup-outline:focus i {
    -webkit-text-fill-color: #fff;
}

.btn-repup-grey {
    background-color: #7B7A7B;
    color: #fff;
    border: 1px solid #7B7A7B;
}
.btn-repup-grey:hover,
.btn-repup-grey:active,
.btn-repup-grey:focus {
    background-color: #646364;
    border-color: #4D4C4D;
}

.btn-repup-grey.btn-repup-outline {
    background-color: #fff;
    color: #7B7A7B;
    border: 1px solid #7B7A7B;
}

.btn-repup-grey.btn-repup-outline:hover,
.btn-repup-grey.btn-repup-outline:active,
.btn-repup-grey.btn-repup-outline:focus {
    color: #fff;
    background-color: #7B7A7B;
    border: 1px solid #7B7A7B;
}

.btn-repup-blue {
    background-color: #3F80CE;
    color: #fff;
    border: 1px solid #3F80CE;
}
.btn-repup-blue:hover,
.btn-repup-blue:active,
.btn-repup-blue:focus {
    background-color: #2869B7;
    border-color: #1152A0;
}

.btn-repup-blue.btn-repup-outline {
    background-color: #fff;
    color: #3F80CE;
    border: 1px solid #3F80CE;
}

.btn-repup-blue.btn-repup-outline:hover,
.btn-repup-blue.btn-repup-outline:active,
.btn-repup-blue.btn-repup-outline:focus{
    color: #fff;
    background-color: #3F80CE;
    border-color: #3F80CE;
}

.btn-repup-orange {
    background-color: #FF5F5A;
    color: #fff;
    border: 1px solid #FF5F5A;
}
.btn-repup-orange:hover,
.btn-repup-orange:active,
.btn-repup-orange:focus{
    background-color: #E84843;
    color: #fff;
    border-color: #D1312C;
}

.btn-repup-orange.btn-repup-outline {
    background-color: #fff;
    color: #FF5F5A;
    border: 1px solid #FF5F5A;
}
.btn-repup-orange.btn-repup-outline:hover,
.btn-repup-orange.btn-repup-outline:active,
.btn-repup-orange.btn-repup-outline:focus {
    color: #fff;
    background-color: #FF5F5A;
    border-color: #FF5F5A;
}

.btn-repup-green {
    background-color: #01CC80;
    color: #fff;
    border: 1px solid #01CC80;
}

.btn-repup-green:hover,
.btn-repup-green:active,
.btn-repup-green:focus {
    background-color: #00B569;
    color: #fff;
    border-color: #009E52;
}

.btn-repup-green.btn-repup-outline {
    background-color: #fff;
    color: #01CC80;
    border: 1px solid #01CC80;
}

.btn-repup-green.btn-repup-outline:hover,
.btn-repup-green.btn-repup-outline:active,
.btn-repup-green.btn-repup-outline:focus {
    background-color: #01CC80;
    color: #fff;
    border: 1px solid #01CC80;
}

.btn-repup-group {
    display: inline-block;
    box-shadow:  5px 5px 5px rgba(0, 0, 0, 0.05);
}

.btn-repup-group button {
    box-shadow: none;
    border-radius: 0px;
}

.btn-repup-group button:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}
.btn-repup-group button:last-child{
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.btn-repup-icon {
    padding: 12.5px 18px;
}
.btn-repup-icon>i {
    margin-right: 0px;
    margin-left: 0px;
    font-size: 14px;
}
.btn-repup-icon.btn-repup-lg {
    padding: 14.5px 20px;
}
.btn-repup-icon.btn-repup-lg>i {
    font-size: 16px;
}
.btn-repup-icon.btn-repup-sm {
    padding: 9.5px 15px;
}
.btn-repup-icon.btn-repup-sm>i {
    font-size: 13px;
}
.btn-repup-icon.btn-repup-xs {
    padding: 7.5px 13px
}

.btn-repup-icon.btn-repup-xs>i {
    font-size: 11px;
}
/*Buttons*/